<template>
  <b-container>
    <b-row class="justify-content-center">
      <b-col cols="12" lg="6" xl="4" md="8" sm="10" class="mb-25">
        <div class="login-form-box">
          <h4>{{ $t("password_reset") }}</h4>
          <p>
            {{ $t("reset_password_text") }}
          </p>
          <b-form-group
            class="account-inputs"
            :label="$t('form_email') + '*'"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="email"
              type="text"
              :state="emailState"
              class="form-item"
              aria-describedby="email-feedback"
              :readonly="readOnlyEmail"
            ></b-form-input>
            <b-form-invalid-feedback id="email-feedback">
              {{ emailError }}
            </b-form-invalid-feedback>
          </b-form-group>
          <div>
            <input
              id="token"
              v-model="token"
              type="hidden"
              :state="tokenState"
              aria-describedby="token-feedback"
            />
            <b-form-invalid-feedback id="token-feedback">
              {{ tokenError }}
            </b-form-invalid-feedback>
          </div>
          <b-form-group
            class="account-inputs"
            id="password-group"
            :label="$t('form_password') + '*'"
            label-for="password"
          >
            <!-- <b-form-input
              id="password"
              v-model="password"
              type="password"
              class="form-item"
              :state="passwordState"
              aria-describedby="password-feedback"
            ></b-form-input> -->
            <div class="form-group password-watch">
              <b-form-input
                v-model="password"
                :type="passwordFieldType"
                :placeholder="$t('password')"
                id="password"
                :state="passwordState"
                aria-describedby="password-feedback"
                class="form-item"
              >
              </b-form-input>
              <span class="show-password">
                <b-link class="span-txt" @click="toggleShowPass">
                  {{
                    passwordFieldType === "password"
                      ? $t("show")
                      : $t("hide")
                  }}
                </b-link>
              </span>
            </div>
            <b-form-invalid-feedback id="password-feedback">
              {{ passwordError }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            class="account-inputs"
            id="password_confirm-group"
            :label="$t('form_password_confirmation') + '*'"
            label-for="password_confirm"
          >
            <!-- <b-form-input
              id="password_confirm"
              v-model="password_confirm"
              type="password"
              class="form-item"
              :state="password_confirmState"
              aria-describedby="password_confirm-feedback"
            ></b-form-input> -->
            
            <div class="form-group password-watch">
              <b-form-input
                id="password_confirm"
                v-model="password_confirm"
                :type="confirmPasswordFieldType"
                :placeholder="$t('password')"
                :state="password_confirmState"
                aria-describedby="password_confirm-feedback"
                class="form-item"
              >
              </b-form-input>
              <span class="show-password">
                <b-link class="span-txt" @click="confirmToggleShowPass">
                  {{
                    confirmPasswordFieldType === "password"
                      ? $t("show")
                      : $t("hide")
                  }}
                </b-link>
              </span>
            </div>

            <b-form-invalid-feedback id="password_confirm-feedback">
              {{ password_confirmError }}
            </b-form-invalid-feedback>
          </b-form-group>
          <div>{{ description }}</div>
          <div
            class="btn-wrap d-flex flex-column justify-content-between align-items-center"
          >
            <div class="cancel-text mb-3">
              <b-button @click="goToLoginPage" class="blue-btn">{{
                $t("cancel")
              }}</b-button>
            </div>
            <div class="btn-holder">
              <b-button @click="formSubmit" class="green-btn">{{
                $t("reset_my_password")
              }}</b-button>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { resetPassword } from "@storefront/core/data-resolver/user";
export default {
  name: "ResetPassword",
  computed: {
    readOnlyEmail() {
      return (this.$route.query.email !== undefined &&
        this.$route.query.email.length) > 0
        ? true
        : false;
    },
  },
  mounted() {
    const bcrumb = { current: this.$t("password_reset"), routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
  },
  metaInfo() {
    return {
      meta: [
        { name: "title", content: this.$t("password_reset") },
        { name: "keywords", content: this.$t("password_reset") },
        { name: "description", content: this.$t("password_reset") },
      ],
      title: this.$t("password_reset"),
    };
  },
  data() {
    return {
      email: this.$route.query.email,
      emailState: null,
      emailError: "",
      description: "",
      password: "",
      passwordFieldType: "password",
      confirmPasswordFieldType: "password",
      passwordState: null,
      passwordError: "",
      password_confirm: "",
      password_confirmState: null,
      password_confirmError: "",
      token: this.$route.query.token,
      tokenState: null,
      tokenError: "",
      emailReg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },
  methods: {
    goToLoginPage() {
      this.$router.push({ name: "login" });
    },
    async formSubmit() {
      if (
        this.validateEmail() &&
        this.validatePassword() &&
        this.validateConfirmationPassword() &&
        this.validateToken()
      ) {
        const retval = await resetPassword(
          this.email,
          this.token,
          this.password
        );
        if (retval == true) {
          this.description = this.$t("password_resetted");
          this.$router.push({ name: "login" });
        } else {
          this.description = this.$t("password_not_resetted");
        }
      }
    },
    validatePassword() {
      if (this.password.length < 8) {
        this.passwordState = false;
        this.passwordError = this.$t("password_needs");
        return false;
      } else if (this.password.match(/[a-z]/) == null) {
        this.passwordState = false;
        this.passwordError = this.$t("password_needs");
        return false;
      } else if (
        this.password.match(/[!"#$%&'()*+,-.:;<=>?@[\]^_`{|}~]/) == null
      ) {
        this.passwordState = false;
        this.passwordError = this.$t("password_needs");
        return false;
      } else if (this.password.match(/[A-Z]/) == null) {
        this.passwordState = false;
        this.passwordError = this.$t("password_needs");
        return false;
      } else if (this.password.match(/\d/) == null) {
        this.passwordState = false;
        this.passwordError = this.$t("password_needs");
        return false;
      } else {
        this.passwordState = true;
        return true;
      }
    },
    validateConfirmationPassword() {
      if (this.password != this.password_confirm) {
        this.password_confirmState = false;
        this.password_confirmError = this.$t("password_confirm_not_equal");
        return false;
      } else {
        this.password_confirmState = true;
        return true;
      }
    },
    validateToken() {
      if (this.token == "") {
        this.tokenState = false;
        this.tokenError = this.$t("token_not_filled");
        return false;
      } else {
        this.tokenState = true;
        return true;
      }
    },
    validateEmail() {
      if (this.email == "") {
        this.emailState = false;
        this.emailError = this.$t("email_is_required_field");
        return false;
      }
      if (!this.emailReg.test(this.email)) {
        this.emailState = false;
        this.emailError = this.$t("email_is_invalid_field");
        return false;
      }
      this.emailState = true;
      return true;
    },
    toggleShowPass() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    confirmToggleShowPass() {
      this.confirmPasswordFieldType =
        this.confirmPasswordFieldType === "password" ? "text" : "password";
    },
  },
};
</script>
